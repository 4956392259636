import React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";

// layouts
import AuthLayout from "layouts/AuthLayout";

// pages
import ResetPassword from "pages/ResetPassword";
import NotFound from "pages/NotFound";

import { getFirebaseDataURL } from "utils/helpers";
import EmailVerification from "pages/EmailVerification";

const App = () => {
  const { status, mode, actionCode } = getFirebaseDataURL();

  if (!status) {
    // error getting action code, return not found page
    return (
      <Routes>
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  switch (mode) {
    case "resetPassword":
      return (
        <Routes>
          <Route element={<AuthLayout />}>
            <Route
              path="*"
              element={<ResetPassword actionCode={actionCode} />}
            />
          </Route>
        </Routes>
      );

    case "verifyEmail":
      return (
        <Routes>
          <Route element={<AuthLayout />}>
            <Route
              path="*"
              element={<EmailVerification actionCode={actionCode} />}
            />
          </Route>
        </Routes>
      );

    default:
      // not found page
      return (
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      );
  }
};

export default App;
