export default {
  NODE_ENV: "production",

  // firebase
  FIREBASE_API_KEY: "AIzaSyD0UY2w5YacipR5KizJCASMzEyGTBxYbfQ",
  FIREBASE_AUTH_DOMAIN: "crewmen-prod.firebaseapp.com",
  FIREBASE_PROJECT_ID: "crewmen-prod",
  FIREBASE_STORAGE_BUCKET: "crewmen-prod.appspot.com",
  FIREBASE_MESSAGING_SENDER_ID: "218550442467",
  FIREBASE_APP_ID: "1:218550442467:web:48957b8e9bab04286228d6",
  FIREBASE_MEASUREMENT_ID: "G-YNZBN2P2SH",

  // URLs
  CONTINUE_URL: "https://crewmen.page.link/v2EF",
};
