import queryString from "query-string";

export const getFirebaseDataURL = () => {
  try {
    // const currentLocation = queryString.parse(window.location.search);
    const link = queryString.parse(window.location.search);

    // Get the action to complete.
    const mode = link.mode;
    const actionCode = link.oobCode;

    return {
      status: true,
      mode: mode,
      actionCode: actionCode,
    };
  } catch (error) {
    console.log("getFirebaseDataURL ~ error", error);
    return { status: false, message: error.message };
  }
};

export const validatePassword = (val) => {
  const mediumRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{10,}/;
  return mediumRegex.test(val);
};
