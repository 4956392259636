import React, { useEffect, useState } from "react";
import { applyActionCode } from "firebase/auth";

import Logo from "assets/images/logo.svg";

import { firebaseAuth } from "lib/firebase";
import config from "config";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const EmailVerification = ({ actionCode }) => {
  // states
  const [loading, setLoading] = useState(true);
  const [prompt, setPrompt] = useState({
    visible: false,
    success: null,
    text: "",
  });

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    try {
      // Verify email
      await applyActionCode(firebaseAuth, actionCode);

      setLoading(false);
      setPrompt({
        visible: true,
        success: true,
      });
    } catch (error) {
      // Invalid or expired action code. Ask user to try to send email verification again.
      setLoading(false);

      let text =
        error.message ||
        "Invalid or expired request. Please try to reset the password again.";

      if (error.code === "auth/invalid-action-code") {
        text =
          "Your request to verify your email has expired or the link has already been used.";
      } else if (error.code === "auth/internal-error") {
        text =
          "Invalid or expired request. Please try to request email verification again.";
      }

      setPrompt({
        visible: true,
        success: false,
        text,
      });
    }
  };

  return (
    <>
      {/* logo */}
      <div className="text-center mb-3">
        <div className="header-brand">
          <ReactSVG className="brand-logo" src={Logo} />
        </div>
      </div>

      {/* loading */}
      {loading ? (
        <div style={styles.loadingContainer}>
          <div className="spinner-grow text-primary" role="status" />
          <span>Loading...</span>
        </div>
      ) : null}

      {/* content */}
      {!loading ? (
        <div className="card-body">
          {/* success or fail prompt */}
          {prompt.visible ? (
            <div
              className={`prompt-container ${
                prompt.success ? "prompt-success" : "prompt-fail"
              }`}
            >
              {/* title */}
              <h5
                className={`prompt-title  ${
                  prompt.success ? "prompt-success" : "prompt-fail"
                }`}
              >
                {prompt.success
                  ? "Email Verification Successful"
                  : "Email Verification Unsuccessful"}
              </h5>

              {/* subtitle */}
              <div className="prompt-subtitle">
                {prompt.success
                  ? "You can now login to your account."
                  : prompt.text}

                {prompt.success ? (
                  // <div className="btn-container">
                  <input
                    className="btn btn-success mt-2"
                    type="submit"
                    value="Continue"
                    onClick={() => window.location.replace(config.CONTINUE_URL)}
                  />
                ) : // </div>
                null}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default EmailVerification;

const styles = {
  loadingContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0",
  },
};
