import React from "react";

const NotFound = () => {
  return (
    <div className="card-body">
      <div className="display-3 text-muted mb-5">
        <i className="fa fa-exclamation" /> <b>404</b>
      </div>
      <h1 className="h3 mb-3">Oops... Page not found</h1>
      <p className="h6 text-muted font-weight-normal mb-3">
        We are sorry but the page you are looking for could not be found
      </p>
      <a className="btn btn-primary" href="https://crewmen.co/">
        <i className="fe fe-arrow-left mr-2" />
        Back to Home
      </a>
    </div>
  );
};

export default NotFound;
