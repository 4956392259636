import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";

import Logo from "assets/images/logo.svg";

import { firebaseAuth } from "lib/firebase";
import { validatePassword } from "utils/helpers";
import config from "config";

const ResetPassword = ({ actionCode }) => {
  // states
  const [loading, setLoading] = useState(true);
  const [resetingPassword, setResetingPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [inputValidation, setInputValidation] = useState({
    password: { error: false, message: "" },
    confirmPassword: { error: false, message: "" },
  });
  const [prompt, setPrompt] = useState({
    visible: false,
    success: null,
    text: "",
  });

  // variables
  const passwordRules =
    "minlength: 10; required: lower; required: upper; required: digit; required: [-];";

  useEffect(() => {
    getEmailAddress();
  }, []);

  const getEmailAddress = async () => {
    try {
      // Verify the password reset code is valid.
      const accountEmail = await verifyPasswordResetCode(
        firebaseAuth,
        actionCode
      );

      setEmail(accountEmail);
      setLoading(false);
    } catch (error) {
      // Invalid or expired action code. Ask user to try to reset the password again.
      setLoading(false);

      let text =
        error.message ||
        "Invalid or expired request. Please try to reset the password again.";

      if (error.code === "auth/invalid-action-code") {
        text =
          "Your request to reset your password has expired or the link has already been used.";
      } else if (error.code === "auth/internal-error") {
        text =
          "Invalid or expired request. Please try to reset the password again.";
      }

      setPrompt({
        visible: true,
        success: false,
        text,
      });
    }
  };

  const handleResetPassword = async () => {
    try {
      // Localize the UI to the selected language as determined by the lang
      // parameter.

      setResetingPassword(true);

      // Save the new password.
      await confirmPasswordReset(firebaseAuth, actionCode, password);

      setResetingPassword(false);
      setPrompt({
        visible: true,
        success: true,
        text: "",
      });
    } catch (error) {
      // Invalid or expired action code. Ask user to try to reset the password again.
      setResetingPassword(false);

      let text =
        error.message ||
        "Invalid or expired request. Please try to reset the password again.";

      if (error.code === "auth/invalid-action-code") {
        text =
          "Your request to reset your password has expired or the link has already been used.";
      } else if (error.code === "auth/internal-error") {
        text =
          "Invalid or expired request. Please try to reset the password again.";
      }

      setPrompt({
        visible: true,
        success: false,
        text,
      });
    }
  };

  const handleValidation = () => {
    let passwordError = { error: false, message: "" };
    let confirmPasswordError = { error: false, message: "" };
    let formIsValid = true;

    // Password
    if (!password) {
      formIsValid = false;
      passwordError = {
        error: true,
        message: "Password cannot be empty.",
      };
    }

    // Confirm password
    if (!confirmPassword) {
      formIsValid = false;
      confirmPasswordError = {
        error: true,
        message: "Confirm password cannot be empty.",
      };
    }

    // strong password
    if (!validatePassword(password) || !validatePassword(confirmPassword)) {
      formIsValid = false;
      confirmPasswordError = {
        error: true,
        message:
          "Password to be a minimum of 10 characters using a mixture of lower case, capital letters and numbers.",
      };
    }

    // match
    if (formIsValid === true && password !== confirmPassword) {
      formIsValid = false;
      confirmPasswordError = {
        error: true,
        message: "Password and Confirm Password should be same.",
      };
    }

    // update input errors if any
    setInputValidation({
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });

    return formIsValid;
  };

  const handleSubmit = (event) => {
    // handle button click event
    event.preventDefault();

    if (handleValidation()) {
      // proceed reset password
      handleResetPassword();
    }
  };

  const validateForm = () => {
    //basic form input validation
    return confirmPassword.length >= 10 && password.length >= 10;
  };

  return (
    <>
      {/* logo */}
      <div className="text-center mb-3">
        <div className="header-brand" to="/">
          <ReactSVG className="brand-logo" src={Logo} />
        </div>
      </div>

      {/* loading */}
      {loading ? (
        <div style={styles.loadingContainer}>
          <div className="spinner-grow text-primary" role="status" />
          <span>Loading...</span>
        </div>
      ) : null}

      {/* content */}
      {!loading ? (
        <div className="card-body">
          {!prompt.visible ? (
            <form onSubmit={handleSubmit}>
              {/* title */}
              <div className="card-title">Reset your password</div>

              {/* preview email */}
              <div className="form-group">
                <label className="form-label" htmlFor="username">
                  Email address
                </label>
                <input
                  disabled
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                  value={email}
                />
              </div>

              {/* password input */}
              <div className="form-group">
                <label className="form-label" htmlFor="passwordInput">
                  New password <span className="text-red">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={hidePassword ? "password" : "text"}
                    className="form-control"
                    id="passwordInput"
                    placeholder="Enter new password"
                    disabled={loading}
                    value={password}
                    passwordrules={passwordRules}
                    onChange={(e) => {
                      inputValidation.password = {
                        error: false,
                        message: "",
                      };
                      setPassword(e.target.value);
                    }}
                  />

                  {/* eye icon */}
                  <div
                    className="input-group-addon"
                    onClick={() => setHidePassword(!hidePassword)}
                  >
                    <i
                      className={`fa ${
                        hidePassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>

                {/* error message */}
                {inputValidation.password.error ? (
                  <div className="text-danger">
                    {inputValidation.password.message}
                  </div>
                ) : null}
              </div>

              {/* confirm password input */}
              <div className="form-group">
                <label className="form-label" htmlFor="confirmPasswordInput">
                  Confirm password <span className="text-red">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={hideConfirmPassword ? "password" : "text"}
                    className="form-control"
                    id="confirmPasswordInput"
                    placeholder="Confirm new password"
                    disabled={loading}
                    value={confirmPassword}
                    passwordrules={passwordRules}
                    onChange={(e) => {
                      inputValidation.confirmPassword = {
                        error: false,
                        message: "",
                      };
                      setConfirmPassword(e.target.value);
                    }}
                  />

                  {/* eye icon */}
                  <div
                    className="input-group-addon"
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  >
                    <i
                      className={`fa ${
                        hideConfirmPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>

                {/* error message */}
                {inputValidation.confirmPassword.error ? (
                  <div className="text-danger">
                    {inputValidation.confirmPassword.message}
                  </div>
                ) : null}
              </div>

              {/* reset button */}
              <div className="form-footer">
                <input
                  className="btn btn-primary btn-block"
                  type="submit"
                  value={resetingPassword ? "Updating..." : "Change Password"}
                  disabled={resetingPassword || !validateForm()}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          ) : null}

          {/* success or fail prompt */}
          {prompt.visible ? (
            <div
              className={`prompt-container ${
                prompt.success ? "prompt-success" : "prompt-fail"
              }`}
            >
              {/* title */}
              <h5
                className={`prompt-title  ${
                  prompt.success ? "prompt-success" : "prompt-fail"
                }`}
              >
                {prompt.success
                  ? "Password reset successful!"
                  : "Password reset Fail!"}
              </h5>

              {/* subtitle */}
              <div className="prompt-subtitle">
                {prompt.success
                  ? "Your password has been successfully reset. You can now login using your new password."
                  : prompt.text}

                {prompt.success ? (
                  <div className="btn-container">
                    <input
                      className="btn btn-success"
                      type="submit"
                      value="Continue"
                      onClick={() =>
                        window.location.replace(config.CONTINUE_URL)
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default ResetPassword;

const styles = {
  loadingContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0",
  },
};
